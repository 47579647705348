<template>
  <div>
    <v-row align="start" justify="center">
      <v-col cols="12" lg="8">
        <v-toolbar tile flat color="transparent">
          <v-toolbar-title>
            DTMF Options
          </v-toolbar-title>
          <v-spacer/>
          <v-btn fab small color="success" elevation="0" @click="Save"><v-icon>mdi-content-save</v-icon></v-btn>
        </v-toolbar>
        <!-- Codec Order -->
        <v-card outlined class="px-3 py-2 mb-3">
          <h3 id="dtmfOrder" class="title">DTMF Order</h3>
          <h4 class="font-weight-light mb-3">Drag and drop the codec order</h4>
          <draggable
              v-model="dtmfOrder"
              group="dtmfOrder"
              @start="drag=true"
              @end="drag=false"
          >
            <v-card
                class="mb-3 pb-0"
                v-for="(option, index) in dtmfOrder"
                :key="index"
                style="cursor: pointer"
                flat
            >
              <v-toolbar dense flat color="info" dark>
                <v-toolbar-title>{{option}}</v-toolbar-title>
              </v-toolbar>
            </v-card>
          </draggable>
        </v-card>
        <!-- Auto gen options -->
        <div
            v-for="(option, index) of options"
            :key="index"
        >
          <v-card outlined class="pa-3 mb-4">
            <h4 :id="option.option" class="text-capitalize font-weight-light pl-3 mb-1">{{option.option}}</h4>
            <v-text-field
                :placeholder="option.option"
                filled
                rounded
                dense
                hide-details
                class="mb-2"
                v-model="config[option.option]"
            />
            <div class="pl-3">
              <div class="font-weight-light" style="white-space: break-spaces;">{{option.hint}}</div>
              <div><span class="blue-grey--text">Default:</span> {{option.default}}</div>
              <div><span class="blue-grey--text">Example:</span> {{option.example}}</div>
              <div v-if="option.validValues">
                <span class="blue-grey--text">Vaild Values:</span>
                <v-chip
                    v-for="value of option.validValues"
                    :key="value"
                    color="primary"
                    class="mx-1"
                    small
                >{{value}}</v-chip>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col cols="4">
        <v-card outlined
                :style="currentScroll > 10 ? 'position: fixed; top: 260px; width: 480px':''"
                class="hidden-md-and-down"
        >
          <v-card-title>Options</v-card-title>
          <v-card-text>
            <h3
                v-for="(item, index) of options"
                :key="index"
                @click="$vuetify.goTo(`#${item.option}`)"
                class="body-1"
                style="cursor: pointer"
            >{{index = index + 1}}: {{item.option}}</h3>
            <v-btn
                v-if="currentScroll > 10"
                color="success"
                style="margin-right: 60px"
                @click="Save"
                block
                class="my-2"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn
                v-if="currentScroll > 10"
                color="primary"
                @click="$vuetify.goTo(0)"
                block
            >
              <v-icon>mdi-arrow-up-bold</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "dtmf",
  components: {draggable},
  props: {
    domain:{
      type: String,
      required: true,
    }
  },
  data() {
    return{
      dtmfOrder: ['rfc2833', 'info', 'audio'],
      config: {},
      currentScroll: 0,
      searchOptions: '',
      options: [
        {
          option: 'dtmfAll',
          hint: 'When set, all supported DTMF modes will be sent together. Not recommended. WebRTC does not support this key.',
          default: '0',
          example: '1',
          validValues: ['0', '1'],
        },
        {
          option: 'rfc2833NegotiateOnly8kHzClockRate',
          hint: 'In theory, the RFC 2833 DTMF RTP packets should use the same clock rate as the selected audio codec:\n' +
              '\n' +
              '“DTMF digits and named telephone events are carried as part of the audio stream, and MUST use the same sequence number and time-stamp base as the regular audio channel to simplify the generation of audio waveforms at a gateway.”\n' +
              '\n' +
              '—RFC 2833\n' +
              '\n' +
              'This means that if an application offers audio codecs with distinct clock rates (e.g. PCMU/8000 and opus/48000/2), it should also offer multiple telephone-event payload types, each with a corresponding distinct clock rate (e.g. telephone-event/8000 and telephone-event/48000). And depending on the final outcome of the audio codec negotiation, the application should then use proper payload number, corresponding to either telephone-event/8000 or telephone-event/48000 when generating RFC 2833 DTMF RTP packets.\n' +
              '\n' +
              'In practice, however, many systems cannot deal with anything else than “telephone-event/8000” regardless of the selected audio codec and its clock rate.\n' +
              '\n' +
              'This option therefore allows switching between RFC 2833 conforming mode (value 0), in which the application offers multiple telephone-event payload types, and legacy mode (value 1) in which only “telephone-event/8000” is offered and recognized.\n' +
              '\n',
          default: '1',
          example: '1',
          validValues: ['0', '1'],
        },
        {
          option: 'rfc2833EnforceDurationIn8KHzTimestampUnits',
          hint: 'In case the rfc2833NegotiateOnly8kHzClockRate option is enabled and the clock rate of the negotiated audio codec differs from 8 kHz, the app can populate the duration field of RFC 2833 DTMF RTP packets with either unmodified timestamp units corresponding to the audio codec clock rate (value 0), or it can adjust the timestamp units to correspond to 8 kHz clock rate (value 1).',
          default: '0',
          example: '1',
          validValues: ['0', '1'],
        },
      ]
    }
  },
  beforeMount() {
    this.$axios.get('acrobits/get-settings', {params:{domain: this.domain}}).then(res=>{
      this.config = Object.assign({}, res.data)
      /*Sort dtmf*/
      let dtmfOrder = this.config.dtmfOrder.split(',')
      this.dtmfOrder.sort((a, b) => dtmfOrder.indexOf(a) - dtmfOrder.indexOf(b))
    })
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  methods:{
    updateScroll() {
      this.currentScroll = window.scrollY
    },
    Save() {
      this.config.dtmfOrder = this.dtmfOrder.map(value => {
        return value
      }).join()
      this.$emit('save-settings', this.config)
    }
  }
}
</script>

<style scoped>

</style>